import { createStore } from "vuex"
 
const store = createStore({
    state() {
        return {
            u_id: '',
            m_id: '',
            phone: '',
            show:false,
            dialogVisible:false,
        }
    },
    getters:{
        getMid(state){  
            if(sessionStorage.getItem('m_id')) return sessionStorage.getItem('m_id');
            return state.m_id;
        },
        getPhone(state){  
            if(sessionStorage.getItem('phone')) return sessionStorage.getItem('phone');
            return state.phone;
        },
        getUid(state){  
            if(sessionStorage.getItem('u_id')) return sessionStorage.getItem('u_id');
            return state.u_id;
        },
        getShow(state){  
            if(sessionStorage.getItem('show')=='true'){ 
                return true;
            } 
            return state.show;
        },

    },
    mutations: {
        setMid(state,m_id) {
            state.m_id = m_id
            sessionStorage.setItem('m_id',m_id)
        },
        setPhone(state,phone) {
            state.phone = phone
            sessionStorage.setItem('phone',phone)
        },
        setUid(state,u_id) {
            state.u_id = u_id
            sessionStorage.setItem('u_id',u_id)
        },
        setShow(state,show) {
            state.show = show
            sessionStorage.setItem('show',show)
        },
        setDialogVisible(state,dialogVisible) {
            state.dialogVisible = dialogVisible 
        }
    }
})

export default store
